// Styles
import 'font-awesome/css/font-awesome.css'
import './assets/fonts/lineicons3/icon-font/scss/lineicons.scss'
import './assets/fonts/icofont/icofont.css'
import './assets/fonts/themify-icons/themify-icons.css'
import 'vue-select/dist/vue-select.css';
import 'hooper/dist/hooper.css';
import './assets/scss/main.scss'
import 'sweetalert2/dist/sweetalert2.min.css'

// Js
window.jQuery = window.$ = require('jquery')
require('@popperjs/core')
require('bootstrap')
// import './assets/js/rangeslider' 
// import './assets/js/jquery.magnific-popup.min.js'
// import './assets/js/slick.js'
// import './assets/js/slider-bg.js'
// import './assets/js/lightbox.js' 
// import './assets/js/imagesloaded.js'
// import './assets/js/custom.js'